import React, { FC } from "react";
import ComingSoonPreview from "./comingSoonPreview";
import { ComingSoon } from "../../models/album";

interface ComingSoonProps {
  comingSoon: ComingSoon[];
}

const ComingSoonAlbums: FC<ComingSoonProps> = ({ comingSoon }) => {
  return (
    <div className="flex flex-col overflow-x-auto">
      <div className="flex flex-row my-0 md:my-12 text-dark-white-64">
        <h1>Coming soon</h1>
      </div>
      <div className="md:grid md:grid-cols-2 xlg:grid-cols-3 md:gap-16 lg:gap-28 xlg:gap-24">
        {comingSoon &&
          comingSoon.map((album) => (
            <div className="flex pt-16 md:pt-0" key={album.title}>
              <ComingSoonPreview album={album} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ComingSoonAlbums;
