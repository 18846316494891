import { Link } from "gatsby";
import React, { FC } from "react";
import { useAlbumBanner } from "../../hooks/album/useAlbumBanner";
import { CuratorExport, NFTExport } from "../../models/exports";
import PreviewCollage from "./previewCollage";
import { useAlbumTokenNFTs } from "../../hooks/album/useAlbumToken";
import { shortenString } from "../../utils/utils";

interface PreviewProps {
  owner: CuratorExport;
  id?: string;
  name: string;
  address: string;
  disabled?: boolean | undefined; //disables Link clicking - so we can also use in album creation
  faded?: boolean | undefined;
  nfts?: NFTExport[];
  slim?: boolean;
  state?: string;
  nftCount?: number;
}

const Preview: FC<PreviewProps> = ({
  owner,
  id,
  name,
  address,
  disabled = false,
  faded = false,
  nfts = [],
  slim = false,
  state,
  nftCount,
}) => {
  // const profilePicUrl = useProfilePic(ownerId);
  const albumBannerUrl = useAlbumBanner(name, owner.address);
  const totalNFTs = nftCount;

  return (
    <Link
      to={`/album/${id}`}
      className={`${
        disabled ? "disabled-link" : ""
      } flex flex-col rounded-sm bg-black shadow-md w-full overflow-hidden flex-grow float-up ${
        faded ? "opacity-50" : ""
      }`}
      style={{ border: "1px solid #333333" }}
    >
      <div className={`flex flex-row p-16 rounded-b-lg`}>
        <div className="w-48 h-48">
          <img
            className="rounded-full object-cover h-full w-full border-2 border-dark-divider"
            src={albumBannerUrl}
          />
        </div>
        <div className="flex flex-col flex-grow ml-16">
          <h2 className="text-dark-light-grey">
            {shortenString(name, slim ? 15 : 25)}
          </h2>
          {state === "pending" ? (
            <div className="flex flex-row justify-start body1 text-dark-red">
              <p>Pending</p>
            </div>
          ) : (
            <div className="flex flex-row justify-start body1 text-dark-white-64">
              <p>
                {owner.username
                  ? owner.username
                  : `${owner.address.slice(0, 4)}...${owner.address.slice(-4)}`}
              </p>
              <p className="body1 pl-4">•</p>
              <p className="body1 pl-4">
                {totalNFTs ? totalNFTs : "--"}{" "}
                {totalNFTs === 1 ? "NFT" : "NFTs"}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={`mx-8 pb-8 ${slim ? "h-139" : "sm:h-139 lg:h-208"}`}>
        <PreviewCollage
          images={
            nfts.length > 5
              ? nfts.slice(0, 5).map((value) => value.assetURL)
              : nfts.map((value) => value.assetURL)
          }
        />
      </div>
    </Link>
  );
};

export default Preview;
