import { Link } from "gatsby";
import React, { FC, useEffect } from "react";
import { useAlbumNftImages } from "../../hooks/album/useAlbumNftImages";
import Loading from "../loading/loading";
import { linkIsVideo } from "../../utils/utils";

interface PreviewCollageProps {
  images: string[];
}

const PreviewCollage: FC<PreviewCollageProps> = ({ images }) => {
  if (images.length === 1) {
    return (
      <div className="flex flex-row h-full w-full">
        {!linkIsVideo(images[0]) ? (
          <img
            className="rounded-sm object-cover w-full max-h-208"
            src={images[0]}
          />
        ) : (
          <video
            loop
            muted
            src={images[0]}
            className="rounded-sm w-full max-h-208 object-cover"
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
      </div>
    );
  } else if (images.length === 2) {
    return (
      <div className="flex flex-row w-full h-full space-x-8">
        {!linkIsVideo(images[0]) ? (
          <img
            className="rounded-sm object-cover w-1/2 max-h-208"
            src={images[0]}
          />
        ) : (
          <video
            loop
            muted
            src={images[0]}
            className="rounded-sm w-1/2 max-h-208 object-cover self-center"
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
        {!linkIsVideo(images[1]) ? (
          <img
            className="rounded-sm object-cover w-1/2 max-h-208"
            src={images[1]}
          />
        ) : (
          <video
            loop
            muted
            src={images[1]}
            className="rounded-sm w-1/2 max-h-208 object-cover self-center"
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
      </div>
    );
  } else if (images.length === 3) {
    return (
      <div className="flex flex-row h-full flex-grow space-x-8 w-full">
        {!linkIsVideo(images[0]) ? (
          <img
            className="rounded-sm object-cover w-1/2 max-h-208"
            src={images[0]}
          />
        ) : (
          <video
            loop
            muted
            src={images[0]}
            className="rounded-sm w-1/2 max-h-208 object-cover self-center"
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
        <div className="flex flex-col w-1/2 h-full space-y-8">
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[1]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[1]}
              />
            ) : (
              <video
                loop
                muted
                src={images[1]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[2]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[2]}
              />
            ) : (
              <video
                loop
                muted
                src={images[2]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (images.length === 4) {
    return (
      <div className="flex flex-row h-full flex-grow space-x-8 w-full">
        <div className="flex flex-col w-1/2 h-full space-y-8">
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[0]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[0]}
              />
            ) : (
              <video
                loop
                muted
                src={images[0]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[1]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[1]}
              />
            ) : (
              <video
                loop
                muted
                src={images[1]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 h-full space-y-8">
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[2]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[2]}
              />
            ) : (
              <video
                loop
                muted
                src={images[2]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
          <div className="flex flex-grow h-47/100">
            {!linkIsVideo(images[3]) ? (
              <img
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                src={images[3]}
              />
            ) : (
              <video
                loop
                muted
                src={images[3]}
                className="rounded-sm object-cover flex-grow"
                style={{ maxHeight: "99px" }}
                onMouseEnter={(e) => e.target.play()}
                onMouseLeave={(e) => e.target.pause()}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (images.length > 4) {
    return (
      <div className="flex flex-row h-full flex-grow space-x-8 w-full">
        {!linkIsVideo(images[0]) ? (
          <img
            className="rounded-sm object-cover w-1/2 max-h-208"
            src={images[0]}
          />
        ) : (
          <video
            loop
            muted
            src={images[0]}
            className="rounded-sm w-1/2 max-h-208 object-cover"
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
        <div className="flex flex-row h-full flex-grow space-x-8">
          <div className="flex flex-col w-1/2 h-full space-y-8">
            <div className="flex flex-grow h-47/100">
              {!linkIsVideo(images[1]) ? (
                <img
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  src={images[1]}
                />
              ) : (
                <video
                  loop
                  muted
                  src={images[1]}
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  onMouseEnter={(e) => e.target.play()}
                  onMouseLeave={(e) => e.target.pause()}
                />
              )}
            </div>
            <div className="flex flex-grow h-47/100">
              {!linkIsVideo(images[2]) ? (
                <img
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  src={images[2]}
                />
              ) : (
                <video
                  loop
                  muted
                  src={images[2]}
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  onMouseEnter={(e) => e.target.play()}
                  onMouseLeave={(e) => e.target.pause()}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col w-1/2 h-full space-y-8">
            <div className="flex flex-grow h-47/100">
              {!linkIsVideo(images[3]) ? (
                <img
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  src={images[3]}
                />
              ) : (
                <video
                  loop
                  muted
                  src={images[3]}
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  onMouseEnter={(e) => e.target.play()}
                  onMouseLeave={(e) => e.target.pause()}
                />
              )}
            </div>
            <div className="flex flex-grow h-47/100">
              {!linkIsVideo(images[4]) ? (
                <img
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  src={images[4]}
                />
              ) : (
                <video
                  loop
                  muted
                  src={images[4]}
                  className="rounded-sm object-cover flex-grow"
                  style={{ maxHeight: "99px" }}
                  onMouseEnter={(e) => e.target.play()}
                  onMouseLeave={(e) => e.target.pause()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    //Just in case we didnt catch any edge cases
    console.log("returning null");
    return null;
  }
};

export default PreviewCollage;
