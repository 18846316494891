import { useEffect } from "react";
import { useState } from "react";
import { ComingSoon } from "../../models/album";
import Api from "../../services/api";

export const useGetComingSoon = (): [
  ComingSoon[] | undefined,
  boolean,
  string | undefined
] => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [comingSoon, setComingSoon] = useState<ComingSoon[] | undefined>();

  useEffect(() => {
    const api = new Api();
    api
      .getComingSoon()
      .then((response) => {
        setComingSoon(response.data.comingSoon);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [comingSoon, loading, error];
};
