import React from "react";
import { Link } from "gatsby";

const FeaturedAlbum = ({ featuredAlbum }) => {
  return (
    <>
      <Link
        to={`/album/${featuredAlbum.fund_name}`}
        className="grid grid-cols-12 sm:gap-0 md:gap-12 lg:gap-24"
      >
        <div
          className="sm:col-span-12 md:col-span-8 overflow-hidden rounded-t-sm md:rounded-sm"
          style={{
            border: "1px solid #333333",
            filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1))",
          }}
        >
          <img
            className="w-full h-192 md:h-401 lg:h-386 xlg:h-508 object-cover"
            src={featuredAlbum.banner_url}
            style={{ WebkitTransform: "translate3d(0,0,0)" }}
          />
        </div>

        <div
          className="sm:col-span-12 md:col-span-4 bg-primary-black rounded-b-sm md:rounded-sm"
          style={{
            border: "1px solid #333333",
            color: `#${featuredAlbum.background_color}`,
          }}
        >
          <div className="hidden md:flex md:flex-col justify-center lg:py-32 xlg:py-48 h-192 md:h-401 lg:h-386 xlg:h-508 md:px-16 lg:px-0">
            <p className="caption pb-8 md:text-center md:pb-16 xlg:pb-48">
              - {featuredAlbum.partner_title} -
            </p>
            <div className="flex justify-center">
              <img
                className="rounded-full w-80 h-80 lg:w-96 lg:h-96 xlg:w-134 xlg:h-134 mr-16 md:mr-0 md:mb-16"
                src={featuredAlbum.profile_url}
              />
            </div>
            <p className="hidden lg:block display1 text-center md:pb-28">
              {featuredAlbum.title}
            </p>
            <h1 className="lg:hidden text-center pb-8">
              {featuredAlbum.title}
            </h1>

            <p className="body2 text-center md:pb-24 xlg:pb-40">
              {featuredAlbum.description}
            </p>

            <div className="flex justify-center pb-16 md:pb-0">
              <div className="md:w-192 xlg:w-270">
                <button
                  className={`text-black h-48 btn-primary w-full text-center hover:opacity-90 hover:shadow-md active:opacity-75 disabled:opacity-50 disabled:text-primary-dark-grey disabled:border-primary-dark-grey disabled:cursor-default focus:ring-transparent focus:outline-none`}
                  style={{
                    borderRadius: "48px",
                    background: `#${featuredAlbum.background_color}`,
                  }}
                >
                  View album
                </button>
              </div>
            </div>
          </div>
          <div className="md:hidden flex flex-col p-16">
            <div className="flex flex-row">
              <img
                className="rounded-full w-80 h-80"
                src={featuredAlbum.profile_url}
              />
              <div className="flex flex-col ml-16">
                <p className="caption pb-8">{featuredAlbum.partner_title}</p>
                <h1 className="pb-8">{featuredAlbum.title}</h1>
                <p className="body2 pb-16">{featuredAlbum.description}</p>
              </div>
            </div>
            <div className="flex justify-center pb-4">
              <div className="w-full">
                <button
                  className={`text-black h-48 btn-primary w-full text-center hover:opacity-90 hover:shadow-md active:opacity-75 disabled:opacity-50 disabled:text-primary-dark-grey disabled:border-primary-dark-grey disabled:cursor-default focus:ring-transparent focus:outline-none`}
                  style={{
                    borderRadius: "48px",
                    background: `#${featuredAlbum.background_color}`,
                  }}
                >
                  View album
                </button>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default FeaturedAlbum;
