import { AlbumExport } from "../models/exports";

//When displaying token name on front-end formats token name to be $sznTOKENNAME
export const tokenNameFormat = (tokenName: string) => {
  if (tokenName.startsWith("$szn")) {
    const tokenArray = tokenName.split("$szn");
    let symbol = tokenArray[1].slice(0, 8).toUpperCase();
    return `$szn${symbol}`;
  } else {
    return `$szn${tokenName.toUpperCase()}`;
  }
};

//Album sorter based on sort option - deep clones albums array, sorts and returns new object
export const sortAlbums = (sortOption: string, albums: AlbumExport[]) => {
  if (!albums) {
    return undefined;
  }
  let copyAlbums = JSON.parse(JSON.stringify(albums));
  switch (sortOption) {
    case "newest":
      return copyAlbums.sort(function (a, b) {
        let dateA = new Date(a.createdAt);
        let dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    case "oldest":
      return copyAlbums.sort(function (a, b) {
        let dateA = new Date(a.createdAt);
        let dateB = new Date(b.createdAt);
        return dateA - dateB;
      });
    case "alphabetically":
      return copyAlbums.sort(function (a, b) {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    case "price - high":
      return copyAlbums;
    case "price - low":
      return copyAlbums;
    default:
      return copyAlbums;
  }
};

//Given eth offered, get how much album tokens we'll receive
export const getEthToAlbumToken = (inputEth: number, tokensPerWei: number) => {
  return inputEth * tokensPerWei;
};

//Given album tokens requested, get how much eth it would cost
export const getAlbumTokenToEth = (
  inputToken: number,
  tokensPerWei: number
) => {
  return inputToken / tokensPerWei;
};
