import { useEffect, useState } from "react";
import Api from "../../services/api";

import DefaultBanner from "../../images/icons/bannerDefault@2x.png";

export const useAlbumBanner = (albumId: string, ownerId: string) => {
  const [bannerUrl, setBannerUrl] = useState();

  useEffect(() => {
    if (albumId && ownerId) {
      const api = new Api();

      api
        .getAlbumBannerUrl(albumId, ownerId)
        .then((response) => {
          setBannerUrl(response);
          // console.log(response);
        })
        .catch((error) => {
          setBannerUrl(DefaultBanner);
        });
    }
  }, [albumId, ownerId]);

  return bannerUrl;
};
