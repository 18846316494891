import React, { FC, Fragment, useState, useEffect } from "react";
import Preview from "../album/preview";
import { AlbumExport } from "../../models/exports";
import { sortAlbums } from "../../utils/albumHelpers";

import { Listbox, Transition } from "@headlessui/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface AllAlbumsProps {
  albums: AlbumExport[];
}

const AllAlbums: FC<AllAlbumsProps> = ({ albums }) => {
  const [sortedAlbums, setSortedAlbums] = useState<AlbumExport[] | undefined>(
    undefined
  );
  const [albumsSort, setAlbumsSort] = useState("Newest");
  const albumsSortOptions = ["Newest", "Oldest", "Alphabetically"];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    switch (albumsSort.toLowerCase()) {
      case "newest":
        setSortedAlbums(sortAlbums("newest", albums));
        break;
      case "oldest":
        setSortedAlbums(sortAlbums("oldest", albums));
        break;
      case "alphabetically":
        setSortedAlbums(sortAlbums("alphabetically", albums));
        break;
      case "price - high":
        setSortedAlbums(sortAlbums("price - high", albums));
        break;
      case "price - low":
        setSortedAlbums(sortAlbums("price - low", albums));
        break;
      default:
        setSortedAlbums(sortAlbums("default", albums));
        break;
    }
  }, [albumsSort]);

  //   useEffect(() => {
  //     const sushi = new SushiSwapService();

  //     Promise.all(
  //       allFunds.map((fund: FundExport) => {
  //         return sushi.getTokenData(fund.tokenAddress);
  //       })
  //     ).then((allFundsPrices) => {
  //       setAllFundsPrices(allFundsPrices);
  //     });
  //   }, [albums]);

  //   const getAllFundPrice = (tokenAddress: string) => {
  //     return allFundsPrices.filter(
  //       (price: TokenData) => price.id === tokenAddress
  //     )[0];
  //   };

  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="flex flex-row my-12 text-dark-white-64">
          <h1>All albums</h1>
        </div>
        <div className="flex flex-row justify-center">
          <Listbox value={albumsSort} onChange={setAlbumsSort}>
            {({ open }) => (
              <>
                <div className="mt-1 relative w-full">
                  <Listbox.Button
                    className="relative no-fill button-base rounded-full py-2 cursor-default focus:outline-none w-full md:w-196 text-dark-red"
                    style={{ height: "40px" }}
                  >
                    <div
                      style={{ padding: "12px 24px" }}
                      className="flex flex-row justify-center"
                    >
                      <span className="flex justify-center">
                        <span className="btn-primary block truncate">
                          {albumsSort}
                        </span>
                      </span>
                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-16 pointer-events-none">
                        <ArrowDropDownIcon
                          className="text-dark-red"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute -top-1 w-full rounded-md justify-items-center overflow-auto focus:outline-none"
                      style={{
                        zIndex: 5,
                        background:
                          "linear-gradient(270deg, #FF4041 0%, #FF6190 29.69%, #FF8B4B 57.81%, #85D6F7 100%)",
                      }}
                    >
                      <div
                        className="rounded-md bg-dark-background items-center py-1"
                        style={{ margin: "2px" }}
                      >
                        {albumsSortOptions.map((sort) => (
                          <Listbox.Option
                            key={sort}
                            className={({ active }) =>
                              classNames(
                                active ? "text-white" : "text-black",
                                "btn-primary cursor-default select-none relative"
                              )
                            }
                            value={sort}
                          >
                            {({ selected }) => (
                              <>
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      selected
                                        ? "text-dark-red"
                                        : "text-dark-white-64",
                                      "btn-primary mx-auto py-8"
                                    )}
                                  >
                                    {sort}
                                  </span>
                                </div>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </div>
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>

      <div className="mt-4 grid sm:grid-cols-1 md:grid-cols-2 xlg:grid-cols-3 gap-16 lg:gap-28 xlg:gap-24 sm:pt-16 md:pt-0">
        {sortedAlbums &&
          sortedAlbums.map((album) => (
            <div className="flex" key={album.name}>
              <Preview
                owner={album.creator}
                name={album.name}
                address={album.albumAddress}
                id={album.id}
                nfts={album.nfts}
                nftCount={album.nftCount}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default AllAlbums;
