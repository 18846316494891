import { useEffect, useState } from "react";
import Api from "../../services/api";

export const useGetAllAlbums = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [albums, setAlbums] = useState<any | undefined>(undefined);

  useEffect(() => {
    const api = new Api();

    api
      .getAllAlbums()
      .then((response) => {
        const albums = response.data.albums;
        setAlbums(albums);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [albums, loading, error];
};
