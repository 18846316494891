import React, { useEffect } from "react";
import Layout from "../components/layout";
import FeaturedAlbum from "../components/explore/featuredAlbum";
import AllAlbums from "../components/explore/allAlbums";
import Loading from "../components/loading/loading";
import { useGetAllAlbums } from "../hooks/funds/useGetAllFunds";
import { useGetFeaturedAlbum } from "../hooks/albums/useGetFeaturedAlbum";
import { useGetComingSoon } from "../hooks/funds/useGetComingSoon";
import ComingSoonAlbums from "../components/explore/comingSoonAlbums";
import SEO from "../components/seo";

const ExplorePage = () => {
  const [albums, loading, error] = useGetAllAlbums();
  const [featuredAlbum, featuredLoading, featuredError] = useGetFeaturedAlbum();
  const [comingSoon, comingSoonLoading, comingSoonError] = useGetComingSoon();
  return (
    <Layout>
      <SEO title="Explore" />
      {loading || featuredLoading || comingSoonLoading ? (
        <div className="flex flex-col h-full place-content-center">
          <div className="mt-16 mx-auto">
            <Loading size={32} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-24 lg:space-y-32 lg:mx-32 px-16 md:px-32 lg:px-0 pb-56 pt-16 md:pt-0">
          <div className="flex flex-col">
            <FeaturedAlbum featuredAlbum={featuredAlbum} />
          </div>
          <div className="flex flex-col">
            <ComingSoonAlbums comingSoon={comingSoon} />
          </div>
          <div className="flex flex-col">
            <AllAlbums albums={albums} />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ExplorePage;
