import React, { FC } from "react";
import { linkIsVideo } from "../../utils/utils";
import { ComingSoon } from "../../models/album";
import { DateTime } from "luxon";

interface ComingSoonPreviewProps {
  album: ComingSoon;
}

const ComingSoonPreview: FC<ComingSoonPreviewProps> = ({ album }) => {
  const convertDateString = (date) => {
    const convertDate = DateTime.fromISO(date);
    return convertDate.toLocaleString(DateTime.DATE_FULL);
  };

  return (
    <div
      className="flex flex-col rounded-sm bg-black shadow-md w-full flex-grow float-up"
      style={{ border: "1px solid #333333" }}
    >
      <div className={`flex flex-row p-16 rounded-b-lg`}>
        <div className="w-48 h-48">
          <img
            className="rounded-full object-cover h-full w-full border-2 border-dark-divider"
            src={album.profile}
          />
        </div>
        <div className="flex flex-col flex-grow ml-16">
          <h2 className="text-dark-light-grey">{album.title}</h2>
          <div className="flex flex-row justify-start body1 text-dark-white-64">
            <p className={album.subtitle ? "pr-4" : ""}>
              {album.subtitle ? `${album.subtitle} •` : ""}
            </p>
            <p className="body1">
              {album.date ? convertDateString(album.date) : "TBD"}
            </p>
          </div>
        </div>
      </div>
      <div className={`flex flex-row mx-8 md:pb-8 h-139 lg:h-216`}>
        {!linkIsVideo(album.banner) ? (
          <img
            className="object-cover w-full max-h-131 lg:max-h-208"
            style={{ borderRadius: "4px" }}
            src={album.banner}
          />
        ) : (
          <video
            loop
            muted
            src={album.banner}
            className="rounded-sm w-full max-h-131 lg:max-h-208 object-cover"
            style={{ borderRadius: "4px" }}
            onMouseEnter={(e) => e.target.play()}
            onMouseLeave={(e) => e.target.pause()}
          />
        )}
      </div>
    </div>
  );
};

export default ComingSoonPreview;
